var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"defect-overview"},[_c('div',{staticClass:"title-box"},[_vm._m(0),_c('el-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.$router.push({ path: '/analysis', query: { tabsIndex: 0, time: _vm.time } })}}},[_vm._v(" View Record ")])],1),_c('div',{staticClass:"defect-overview__content _flex"},[_c('div',{staticClass:"defect-overview__content__left _font-weight__600",attrs:{"data-label":"Total Defects"}},[_vm._v(" "+_vm._s(_vm.sum)+" ")]),_c('div',{staticClass:"defect-overview__content__right"},_vm._l((_vm.progress),function(item){return _c('div',{key:item.key,staticClass:"_flex defect-overview__right__item _font-weight__600 _font-size__sub__heading"},[_c('div',{staticClass:"defect-overview__right__label"},[_vm._v(_vm._s(item.label))]),_c('div',{staticClass:"defect-overview__right__progress",style:({
            background: item.color,
            minWidth: '28px',
            width: Number(_vm.detail[item.key])
              ? 'calc(' + (_vm.detail[item.key] / _vm.maxNum) * 100 + '%' + ' - 75px - 20px)'
              : '0px'
          })},[_vm._v(" "+_vm._s(_vm.detail[item.key])+" ")])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box__icon"},[_c('img',{attrs:{"src":require("../../../assets/icons/dashboard/Defect Overview.png")}}),_c('span',[_vm._v("Inspection Defect Overview")])])
}]

export { render, staticRenderFns }